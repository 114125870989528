import React, { useState, useEffect } from 'react';
// import * as d3 from "d3";
import data from '../json/investigations2.json';
// import { Preview, print } from 'react-html2pdf';
import html2pdf from 'html2pdf.js';
import axios from '../tools/axiosWrapper';


import '../css/item.css';

const ReferenceList = ({ item }) => {
	const MAX_REFERENCE_COUNT = 50;
	const elements = [];

	for (let i = 1; i < MAX_REFERENCE_COUNT; i++) {
		const refText = item['ref' + i];
		
		if (refText) {

			elements.push(
				<p>{`${i}. ` + refText}</p>
			);
		}
	}

	return elements;
};

const Item = ({ params }) => {


	useEffect(() => {
		fetchItem();
	}, []);

	const [isLoading, setLoading] = useState(false);
	const [item, setItem] = useState({});

	const fetchItem = () => {
		setLoading(true);

		const id = params.id;
		let raw = {};

		axios.get(`/papers/${id}`)
			.then(response => {
				raw = response.data;
				console.log(raw)

				var medias = Object.keys(raw)
					.filter(key => key.includes('mediacontent'))
					.reduce((obj, key) => {
						obj[key] = raw[key];
						return obj;
					}, {});

				var mediaLinks = Object.values(medias);
				mediaLinks = mediaLinks.filter(Boolean);

				var finalMediaLinks = mediaLinks.map(i => 'https://drive.google.com/uc?export=view&id=' + i.substring(51))

				function replaceIMGS(str) {
					var i = 1;
					var imgIndex,
						placeMediaValue;
					while (i) {
						imgIndex = str.indexOf('@placeMediaContent')
						if (imgIndex != -1) {
							placeMediaValue = str.charAt(imgIndex + 18)
							str = str.replace(/@placeMediaContent\d/, `<img class='media' src='${finalMediaLinks[placeMediaValue - 1]}'/>`)
						} else {
							i = 0
						}
					}
					return str
				}
				raw.introduction = replaceIMGS(raw.introduction)
				raw.background = replaceIMGS(raw.background)
				raw.materials_methods = replaceIMGS(raw.materials_methods)
				raw.predictions = replaceIMGS(raw.predictions)
				raw.discussion = replaceIMGS(raw.discussion)



				setItem(raw);


			})
			.finally(() => {
				setLoading(false);
			});
	}


	function pDF(){
	  const el = document.getElementById('content');
	
	  var cln = el.cloneNode(true);
	    cln.style.margin = ('0px')
	    cln.style.padding = ('40px')
	
	    html2pdf()
	      .set({
	        pagebreak: { mode: 'avoid-all' }
	      })
	      .from(cln)
	
	      .save();
	}

	if (isLoading) {
		return <p>Loading paper...</p>;
	}

	return (
		<>
			<div>

			<button onClick={() => pDF()}>Print</button>
			</div>

			<div className='content' id='content'>



				<div className='title'>

					<div className='titlename'>
						{item.title}
					</div>
					<div className='author'>
						{item.authors}
					</div>
					<div className='date'>
						{item.dateline}
					</div>
				</div>


				<div className='study'>

					<section id='summary' className='section'>

						<h3>Summary</h3>
						<p>
							<i>
								{item.summary_conflict}
							</i>
						</p>
						<p>
							{item.summary_text}
						</p>
					</section>

					<section id='studies' className='section'>
						<h3>Studies</h3>
						<p>
							<ul>
								<li dangerouslySetInnerHTML={{ __html: item.summary_study1 }}>
								</li>
							</ul>
						</p>

					</section>

					<section id='introduction' className='section'>
						<h3>Introduction</h3>
						<p dangerouslySetInnerHTML={{ __html: item.introduction }}>
						</p>
					</section>

					<section id='background' className='section'>
						<h3>Background</h3>
						<p dangerouslySetInnerHTML={{ __html: item.background }}>
						</p>
					</section>

					<section id='materials...' className='section'>
						<h3>Materials and Methods</h3>
						<h4>Null Hypothesis Testing</h4>
						<p dangerouslySetInnerHTML={{ __html: item.materials_methods }}>
						</p>
					</section>

					<section id='studies...' className='section'>
						<h3>Studies</h3>
						{/* <h4>Desired Outcome</h4>
						<p>
							{item.studies_outcome}
						</p> */}
					</section>


					{/* <section id='clause' className='section'>
						<p>
							<b>Construct </b>
							{item.studies_construct}
						</p>
						<p>
							<b>Such that </b>
							{item.studies_suchthat}
						</p>
					</section> */}
					<section id='transition' className='section'>
						<p>
							{item.studies_transistion ? item.studies_transistion : ''}
						</p>
					</section>


					<section className='section'>
						{/* <h3>Enumerated Studies</h3> */}

						{item.studies_title1 ? <h4 dangerouslySetInnerHTML={{ __html: 'Study 1. ' + item.studies_title1 }}></h4> : ''}
						{item.studies_content1 ? <p dangerouslySetInnerHTML={{ __html: item.studies_content1 }}></p> : ''}

					</section>

					{/* <section className='section'>
						<h3>Predicted Events</h3>
						<p dangerouslySetInnerHTML={{ __html: item.predictions }}></p>
					</section> */}

					<section id='discussion' className='section'>
						<h3>Discussion</h3>
						<p dangerouslySetInnerHTML={{ __html: item.discussion }}>
						</p>
					</section>

					<section id='acknowledgements' className='section'>
						<h3>Acknowledgements</h3>
						<p>{item.acknowledgements}</p>
					</section>

					<section id='citation' className='section'>
						<h3>Citation</h3>
						<p>
							Be sure to cite this writing in all related and inspired work.
						</p>
						<p>{item.citation}</p>
					</section>

					<section id='references' className='section'>
						<h3>References</h3>
						<ReferenceList item={item} />
					</section>

				</div>
			</div>
		</>


	)

}

export default Item

import React, { useState, useEffect } from 'react';
// import * as d3 from "d3";
import '../css/item.css';
import { useParams } from 'react-router-dom'

import StudyNav from './studynav.js';
import StudyContent from './studycontent.js';


const StudyPage = () => {
    const params = useParams();
    console.log(params)

    return (
      
      <main>
        <StudyNav />
        <StudyContent params={ params }/>
      </main>
    )
}

export default StudyPage

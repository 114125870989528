import React from "react";

import {
  Link,
} from "react-router-dom";


import '../css/list.css';


const StudyList = ({ ids }) => {
  return(
    <>


      {/* <div className='listpage'>
          {
            data.map(d =>
              <Link to={`${d.PaperNumber}`}>
                <div className='listitem'>
                  <div className='listtitle'>
                    {d.Title}
                  </div>
                  <div className='listauthor'>
                    {
                      (d.Authors.endsWith('.') ? d.Authors : d.Authors+'.' )
                    }
                  </div>
                  <div className='listdate'>
                  </div>
                </div>
              </Link>
            )
          }
      </div> */}

      <div className='listpage'>
        {
          ids.map(d =>
            <Link to={`${d}`}>
              <div className='listitem'>
                <div className='listtitle'>
                  Paper #{d}
                </div>
              </div>
            </Link>
          )
        }
      </div>
    </>
  )

}



export default StudyList

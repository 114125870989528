import React, { useEffect, useState } from "react"

import axios from './tools/axiosWrapper';


import StudyList from "./components/studylist";


const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [canAccess, setCanAccess] = useState(false);
    
    const [ids, setIds] = useState([]);


    useEffect(() => {
        setIsLoading(true);
        
        axios.get('/auth/me')
            .then(response => {
                if (response === 'false') {
                    setIsLoggedIn(false);
                } else {
                    setIsLoggedIn(true);
                    return axios.get('/auth/status');
                }
            })
            .then(response => {
                const { data } = response;

                if (!data.can_access || !data.is_admin) {
                    return false;
                }
                
                setCanAccess(true);
                return axios.get('/papers/');
            })
            .then(response => {
                setIds(response.data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])

    if (isLoading) {
        return <p>Loading...</p>
    }

    if (!isLoggedIn || !canAccess) {
        return <p>You are not authorized to access the Tech Studies Printshop. Please log in or contact an administrator.</p>
    }

    return (
        <main>
            <StudyList ids={ids} />
        </main>
    );
};


export default Dashboard;
import React, {useState, useEffect} from 'react';
import '../css/nav.css';

const StudyNav = () => {

  const navItems = ['summary', 'introduction', 'background', 'materials...', 'studies...', 'discussion', 'acknowledgements', 'citation', 'references'];

  return (

    <div className='nav-box'>
      <nav>
        {
          navItems.map(i => (
              <a href={`#${i}`}>
                <button>
                  <div className='tick'></div>
                  <div className='label'>{i}</div>
                </button>
              </a>
            )
          )
        }
      </nav>
    </div>

)

}

export default StudyNav
